"use strict";

(function () {
	function r(e, n, t) {
		function o(i, f) {
			if (!n[i]) {
				if (!e[i]) {
					var c = "function" == typeof require && require;if (!f && c) return c(i, !0);if (u) return u(i, !0);var a = new Error("Cannot find module '" + i + "'");throw a.code = "MODULE_NOT_FOUND", a;
				}var p = n[i] = { exports: {} };e[i][0].call(p.exports, function (r) {
					var n = e[i][1][r];return o(n || r);
				}, p, p.exports, r, e, n, t);
			}return n[i].exports;
		}for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) {
			o(t[i]);
		}return o;
	}return r;
})()({ 1: [function (require, module, exports) {
		"use strict";

		var Global = require('./global'); // require Global only if you need it
		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				accCol: $('.js-acc-col'),
				accSectBtn: $('.js-acc-section-btn'),
				accSect: $('.js-acc-section'),
				accScrollTarget: $('.js-scroll-target'),
				uniAcc: $('.js-uni-acc-btn'),
				accCPgBtn: $('.js-current-pg-btn')
			},

			classes: {
				open: 'open'
			},

			attr: {
				targetId: 'data-target-id'
			},

			active: null,

			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {
				// get dom and strings
				var self = this,
				    $dom = this.$dom,
				    classes = this.classes,
				    attr = this.attr,
				    ww = Global.vars.windowWidth; // this variable is called from global.js file

				if ($dom.accSect.length > 0) {
					self.checkUrlForTarget();

					Global.$dom.window.on('popstate', function () {
						self.checkUrlForTarget();
					});

					$dom.accSectBtn.on('click', function (e) {
						e.preventDefault();
						self.active = this.getAttribute(attr.targetId);
						self.changeCurrentAcc();
					});

					$dom.accCPgBtn.on('click', function (e) {
						e.preventDefault();
						var url = $(this).attr('href');
						self.checkUrlForTarget(url);
					});
				}

				if ($dom.uniAcc.length > 0) {
					$dom.uniAcc.on('click', function () {
						$(this).toggleClass(classes.open);
						$(this).siblings('.js-uni-acc-child').stop().slideToggle();
					});
				}
			},

			checkUrlForTarget: function checkUrlForTarget(url) {
				var self = this,
				    urlArr = url || window.location.toString();

				urlArr = urlArr.split('#');
				self.active = urlArr.length > 1 ? urlArr[urlArr.length - 1] : null;
				if (self.active) {
					// if (!url) window.scrollTo(0, 0);
					self.changeCurrentAcc();
				}
			},

			changeCurrentAcc: function changeCurrentAcc() {
				var self = this,
				    $dom = this.$dom,
				    attr = this.attr,
				    classes = this.classes;

				$dom.accSect.each(function () {
					if (this.getAttribute('id') == self.active) {
						$(this).stop().slideDown();
					} else {
						$(this).stop().slideUp();
					}
				});

				$dom.accCol.each(function () {
					if (this.getAttribute(attr.targetId) == self.active) {
						$(this).addClass(classes.open);
					} else {
						$(this).removeClass(classes.open);
					}
				});

				setTimeout(function () {
					Global.functions.scrollToTarget($dom.accScrollTarget);
				}, 100);
			}
		};
	}, { "./global": 5 }], 2: [function (require, module, exports) {
		"use strict";

		var Global = require('./global');
		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				wpAdminBar: $('#wpadminbar'),
				siteHeader: $('.site-header')
			},

			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {
				var $dom = this.$dom;

				function moveIfAdminBar() {
					$dom.siteHeader.css('top', $dom.wpAdminBar.height());
					$dom.wpAdminBar.css('position', 'fixed');
				};

				function addGlobalContentOffset() {
					var admBarH = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

					$('.global-margin-top').css('margin-top', $dom.siteHeader[0].getBoundingClientRect().bottom + 'px');
					$('.global-padding-top').css('padding-top', $dom.siteHeader[0].getBoundingClientRect().bottom + 'px');
					$('.global-admin-margin').css('margin-top', admBarH + 'px');
				}

				if ($dom.wpAdminBar.length > 0) {
					moveIfAdminBar();
					addGlobalContentOffset($dom.wpAdminBar.height());
				} else {
					addGlobalContentOffset();
				}

				Global.$dom.window.on('resize', function () {
					clearTimeout(window.resizedFinished);
					window.resizedFinished = setTimeout(function () {
						if ($dom.wpAdminBar.length > 0) {
							moveIfAdminBar();
							addGlobalContentOffset($dom.wpAdminBar.height());
						} else {
							addGlobalContentOffset();
						}
					}, 250);
				});

				Global.$dom.window.on('scroll', function () {
					stickyMenu();
				});

				stickyMenu();

				function stickyMenu() {
					if (Global.$dom.window.scrollTop() < 10) {
						$dom.siteHeader.removeClass('sticky');
					} else {
						$dom.siteHeader.addClass('sticky');
					}
				}
			}
		};
	}, { "./global": 5 }], 3: [function (require, module, exports) {
		"use strict";

		var Global = require('./global');
		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				choiceWrap: $('.js-choice-wrap'),
				choiceOtherInput: $('.js-choice-other')
			},

			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {

				var animLbl = function animLbl(params) {
					this.fields = $(params.fields);
					this.lblselector = params.lblselector;
					this.animclass = params.animclass;
					this.wrapper = params.wrapper;
				};

				animLbl.prototype = {
					moveLabelOut: function moveLabelOut(obj) {
						obj.closest(this.wrapper).find(this.lblselector).addClass(this.animclass); // change nuber of "parent selectors" to make sure it finds proper label
					},
					moveLabelIn: function moveLabelIn(obj) {
						obj.closest(this.wrapper).find(this.lblselector).removeClass(this.animclass); // change nuber of "parent selectors" to make sure it finds proper label
					},
					init: function init() {
						for (var i = 0; i < this.fields.length; i++) {
							$(this.fields[i]).on('focusin', $.proxy(function (e) {
								this.moveLabelOut($(e.currentTarget));
							}, this));

							$(this.fields[i]).on('blur', $.proxy(function (e) {
								if ($(e.currentTarget).val() == "" && !$(e.currentTarget).is(':focus')) {
									this.moveLabelIn($(e.currentTarget));
								}
							}, this));
						}
					}
				};

				var mylabels = new animLbl({
					fields: '.js-has-c-label', // add field selector, devide them with comma (,) for multiple
					lblselector: '.js-c-label', // use your label selector
					wrapper: '.js-c-label-wrap', // common parent for label and input field so that the only label which gets animated is the one connected to a field.
					animclass: 'focused-form' // name of the class which needs to be added to label
				});

				mylabels.init();
			}
		};
	}, { "./global": 5 }], 4: [function (require, module, exports) {
		"use strict";

		var Global = require('./global'); // require Global only if you need it
		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				circular: $('.js-circular')
			},

			selecotrs: {
				icon: '.js-c-icon',
				line: '.js-c-line',
				lineContainer: '.js-c-line-cont',
				circle: '.js-c-circle',
				bgCircle: '.js-c-bg-circle'
			},

			classes: {
				open: 'open',
				hover: 'hovered'
			},

			attr: {},

			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {
				// get dom and strings
				var self = this,
				    $dom = this.$dom,
				    selectors = this.selecotrs,
				    classes = this.classes,
				    attr = this.attr,
				    ww = Global.vars.windowWidth; // this variable is called from global.js file

				$dom.circular.each(function (i, el) {
					var circles = $(this).find(selectors.circle).get().reverse(),
					    bgCircles = $(this).find(selectors.bgCircle).get().reverse(),
					    icon = $(this).find(selectors.icon),
					    lineContainer = $(this).find(selectors.lineContainer).get().reverse(),
					    lines = $(this).find(selectors.line).get().reverse(),
					    contX = $(this).offset().left + $(this).width() / 2,
					    contY = $(this).offset().top + $(this).height() / 2 - 1;

					lineContainer = $(lineContainer);
					circles = $(circles);
					bgCircles = $(bgCircles);
					lines = $(lines);

					var startingR = icon.outerHeight();

					circles.each(function (index) {
						var diameter = startingR + (index + 1) * 20,
						    radius = diameter / 2;

						$(this).attr('r', radius);
						bgCircles.eq(index).attr('r', radius);

						var cricleCenterX = contX,
						    cricleCenterY = contY,
						    lineY = lines.eq(index).offset().top,
						    deltaY = lineY - cricleCenterY,
						    deltaX = cricleCenterX,
						    intersection = Math.sqrt(Math.abs(deltaY * deltaY - (radius + 1) * (radius + 1))) + deltaX,
						    lineContainerX = lineContainer.eq(index).offset().left,
						    lineWidth = Math.abs(intersection - lineContainerX) + 1,
						    angle = Math.atan((lineY - cricleCenterY) / (intersection - cricleCenterX)) * (180 / Math.PI) + .5;

						$(this).css('transform', 'rotate(' + angle + 'deg)');

						var colorTimeout = void 0;
						lineContainer.eq(index).hover(function (e) {
							colorTimeout = setTimeout(function () {
								circles.eq(index).addClass(classes.hover);
							}, 250);
						}, function () {
							circles.eq(index).removeClass(classes.hover);
							clearTimeout(colorTimeout);
						});

						lines.eq(index).css({
							'width': lineWidth,
							'transform': 'translateX(-100%)'
						});
					});
				});
			}
		};
	}, { "./global": 5 }], 5: [function (require, module, exports) {
		"use strict";

		module.exports = {
			$dom: {
				window: $(window),
				body: $('body'),
				siteHeader: $('.site-header')
			},

			vars: {
				windowWidth: window.innerWidth
			},

			functions: {
				escKey: function escKey(callback) {
					$(document).on('keyup', function (e) {
						if (e.keyCode === 27) {
							callback();
						}
					});
				},

				clickOutsideContainer: function clickOutsideContainer(selector, container, closeBtn, callback) {
					selector.on('click', function (e) {
						if (!container.is(e.target) && container.has(e.target).length === 0 && !closeBtn.is(e.target)) {
							callback();
						}
					});
				},

				scrollToTarget: function scrollToTarget(target) {
					var offset = target.offset().top - module.exports.$dom.siteHeader.outerHeight() - 40;
					$('html, body').animate({
						scrollTop: offset
					}, 500);
				},

				isInScrollableView: function isInScrollableView(elem, refElem) {
					var offsetTop = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
					var offsetBottom = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

					var contHeight = refElem.outerHeight(),
					    elemTop = elem.offset().top - refElem.offset().top,
					    elemBottom = elemTop + elem.outerHeight();

					if (elemTop < contHeight * (1 - offsetBottom) && elemBottom >= contHeight * offsetTop) {
						return true;
					}

					return false;
				}
			}
		};
	}, {}], 6: [function (require, module, exports) {
		"use strict";

		var Global = require('./global'); // require Global only if you need it
		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				btn: $('.js-industry-btn'),
				line: $('.js-industry-line'),
				innerGlobe: $('.js-inner-globe')
			},

			selecotrs: {},

			classes: {
				open: 'open',
				hover: 'hovered'
			},

			lineLengths: {},
			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {
				// get dom and strings
				var self = this,
				    $dom = this.$dom,
				    classes = this.classes,
				    ww = Global.vars.windowWidth; // this variable is called from global.js file

				var timeout = void 0;

				$dom.line.each(function (index) {
					var length = this.getTotalLength();
					self.lineLengths[index] = length;
					$(this).css({
						'stroke-dasharray': length,
						'stroke-dashoffset': length
					});
				});

				$dom.btn.each(function (index) {
					$(this).hover(function (e) {
						$dom.line.eq(index).css('stroke-dashoffset', 0);
						timeout = setTimeout(function () {
							$dom.innerGlobe.addClass(classes.hover);
						}, 1000);
					}, function () {
						$dom.line.eq(index).css('stroke-dashoffset', self.lineLengths[index]);
						$dom.innerGlobe.removeClass(classes.hover);
						clearTimeout(timeout);
					});
				});
			}
		};
	}, { "./global": 5 }], 7: [function (require, module, exports) {
		"use strict";

		var Global = require('./global');
		var Misc = require('./misc-scripts');
		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				menuBtn: $('.js-menu-btn'),
				menuNav: $('.js-main-nav'),
				menuHasSub: $('.menu-item-has-children'),
				menuWrap: $('.js-menu-wrap')
			},

			classes: {
				open: 'open',
				openMenu: 'menu-open'
			},

			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {
				// get dom and strings
				var $dom = this.$dom;
				var classes = this.classes;

				// functions
				function closeNav() {
					$dom.menuBtn.removeClass(classes.open);
					$dom.menuNav.removeClass(classes.open);
					$dom.menuWrap.removeClass(classes.open);
					Global.$dom.body.removeClass(classes.openMenu);
				}

				$dom.menuHasSub.each(function (i, el) {
					$(el).append('<span class="sub-icon font-plus-circle" data-open-sub></span>');
				});

				// bind events
				$dom.menuBtn.on('click', function (e) {
					e.preventDefault();
					$dom.menuBtn.toggleClass(classes.open);
					$dom.menuNav.toggleClass(classes.open);
					$dom.menuWrap.toggleClass(classes.open);
					Global.$dom.body.toggleClass(classes.openMenu);
					Misc.closeAllElements();
				});

				$dom.menuNav.on('click', '[data-open-sub]', function () {
					if (Global.vars.windowWidth < 768) {
						$(this).siblings('.sub-menu').stop().slideToggle();
					}
				});

				Global.functions.clickOutsideContainer(Global.$dom.body, $dom.menuWrap, $dom.menuBtn, closeNav);

				Global.functions.escKey(closeNav);
			}
		};
	}, { "./global": 5, "./misc-scripts": 8 }], 8: [function (require, module, exports) {
		"use strict";

		var Global = require('./global'); // require Global only if you need it
		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				openBtn: $('.js-open-target-btn'),
				wpcf7Elm: $('.js-contact-form-cont .wpcf7'),
				contSubmitBtn: $('.js-contact-form-cont .wpcf7 [type="submit"]'),
				trainingType: $('.js-training-type'),
				clickableTr: $('.js-clickable-tr'),
				animatable: $('.animatable'),
				globForm: $('.js-glob-form'),
				commentFields: $('.js-comments-req-f'),
				commentsSubmit: $('#commentform #submit')
			},

			classes: {
				open: 'open',
				active: 'is-active'
			},

			attr: {
				target: 'data-target'
			},

			selectors: {
				spinner: '.js-c-spinner-cont',
				commentsMsg: '.js-c-error-msg',
				commentsMsgEmail: '.js-c-error-email'
			},

			opElements: [],
			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {
				// get dom and strings
				var self = this,
				    $dom = this.$dom,
				    classes = this.classes,
				    attr = this.attr,
				    selectors = this.selectors,
				    ww = Global.vars.windowWidth; // this variable is called from global.js file

				// $('#commentform').removeAttr('novalidate');

				$dom.commentsSubmit.on('click', function (e) {

					var commentsValid = validateComments();

					if (commentsValid === true) {
						$('#commentform').submit();
					} else {
						e.preventDefault();
					}
				});

				function validateComments() {
					var valid = true;
					$dom.commentFields.each(function () {
						if (!$(this).val()) {
							valid = false;
							$(this).siblings(selectors.commentsMsg).addClass('has-error');
						} else {
							$(this).siblings(selectors.commentsMsg).removeClass('has-error');
						}

						if ($(this).attr('type') == 'email') {
							if ($(this).is(':invalid')) {
								valid = false;
								$(this).siblings(selectors.commentsMsgEmail).addClass('has-error');
							} else {
								$(this).siblings(selectors.commentsMsgEmail).removeClass('has-error');
							}
						}
					});
					return valid;
				}
				$dom.openBtn.each(function () {
					var targetEl = $(this).attr(attr.target);
					targetEl = $(targetEl);
					self.opElements.push(targetEl);

					$(this).on('click', function () {
						self.toggleOpen(targetEl);
					});
				});

				Global.functions.escKey(closeAllElements);

				Global.functions.clickOutsideContainer(Global.$dom.body, $dom.globForm, $dom.openBtn, closeAllElements);

				function closeAllElements() {
					self.closeAllElements();
				}

				$dom.contSubmitBtn.on('click', function () {
					$(this).siblings(selectors.spinner).addClass(classes.active);
				});

				$dom.wpcf7Elm.each(function () {
					this.addEventListener('wpcf7submit', function (event) {
						$(this).find(selectors.spinner).removeClass(classes.active);
					}, false);
				});

				$dom.clickableTr.each(function () {
					var link = $(this).find('a');
					if (link.length > 0) {
						$(this).on('click', function () {
							var href = link.eq(0).attr('href');
							window.location.href = href;
						});
					}
				});

				if ($dom.trainingType.length > 0) {
					try {
						$dom.trainingType.val(training_glob);
					} catch (error) {
						console.log(error, 'training_glob is not defined');
					}
				}

				;
				(function ($, win) {
					$.fn.inViewport = function (cb) {
						return this.each(function (i, el) {
							function visPx() {
								var elH = $(el).outerHeight(),
								    offset = 60,
								    H = $(win).height(),
								    r = el.getBoundingClientRect(),
								    t = r.top + offset,
								    b = r.bottom - offset;
								return cb.call(el, Math.max(0, t > 0 ? Math.min(elH, H - t) : Math.min(b, H)));
							}
							visPx();
							$(win).on("resize scroll", visPx);
						});
					};
				})(jQuery, window);

				// calling function if object is in viewport
				$(".animatable").inViewport(function (px) {
					if (px) $(this).addClass('start-anim');
				});
			},

			toggleOpen: function toggleOpen(element) {
				element.toggleClass(this.classes.open);
			},

			closeAllElements: function closeAllElements() {
				var classes = this.classes;
				for (var i = 0; i < this.opElements.length; i++) {
					var element = this.opElements[i];
					element.removeClass(classes.open);
				}
			}
		};
	}, { "./global": 5 }], 9: [function (require, module, exports) {
		"use strict";

		var Global = require('./global');
		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				choiceWrap: $('.js-choice-wrap'),
				choiceOtherInput: $('.js-choice-other'),
				choiceLabel: $('.js-choice-label')
			},

			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {
				var self = this,
				    $dom = this.$dom;
				// choiceInputs = $dom.choiceWrap.find('input');

				var optionalField = function optionalField(fields, inputwrap, label) {
					var self = this;
					self.feields = fields;
					self.inputs = inputwrap.find('input');
					self.state = false;
					self.label = label;

					self.changeState = function () {
						if (self.state === true) {
							self.feields.removeAttr('readonly').removeClass('disabled');
							self.label.removeClass('disabled');
						} else {
							self.feields.attr('readonly', '').addClass('disabled');
							self.feields.val('');
							self.label.addClass('disabled');
						}
					};

					self.feields.on('click', function () {
						self.state = true;
						self.inputs.eq(self.inputs.length - 1).prop('checked', true);
						self.changeState();
					});

					self.inputs.each(function (index) {
						if ($(this).is(":checked") == true) {
							self.state = self.inputs.length - 1 == index ? true : false;
						}
						$(this).on('change', function () {
							self.state = self.inputs.length - 1 == index ? true : false;
							self.changeState();
						});
					});

					self.changeState();
				};

				var requestFormCh = new optionalField($dom.choiceOtherInput, $dom.choiceWrap, $dom.choiceLabel);
			}
		};
	}, { "./global": 5 }], 10: [function (require, module, exports) {
		"use strict";

		var Global = require('./global');
		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				expandBtn: $('.js-expand-btn'),
				field: $('.js-search-field'),
				fieldwrap: $('.js-s-input-wrap'),
				label: $('.js-search-lbl')
			},

			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {
				var $dom = this.$dom;

				$dom.expandBtn.on('click', function () {
					$dom.field.focus();
					activateSearchForm();
				});

				$dom.field.on('focus', function () {
					activateSearchForm();
				});

				$dom.field.on('blur', function () {
					deactivateSearchForm();
				});

				function activateSearchForm() {
					$dom.expandBtn.removeClass('search-f__expand-btn--active');
					$dom.fieldwrap.addClass('search-f__input-wrap--active');
					$dom.field.addClass('search-f__input--active');
					$dom.label.removeClass('search-f__label--active');
				}

				function deactivateSearchForm() {
					if (!$dom.field.val()) {
						$dom.expandBtn.addClass('search-f__expand-btn--active');
						$dom.fieldwrap.removeClass('search-f__input-wrap--active');
						$dom.field.removeClass('search-f__input--active');
						$dom.label.addClass('search-f__label--active');
					}
				}

				if ($dom.field.val()) {
					activateSearchForm();
				}
			}
		};
	}, { "./global": 5 }], 11: [function (require, module, exports) {
		"use strict";

		var Global = require('./global'); // require Global only if you need it
		module.exports = {
			/*-------------------------------------------------------------------------------
   	# Cache dom and strings
   -------------------------------------------------------------------------------*/
			$dom: {
				slider: $('.js-slider'),
				servicesSlider: $('.js-services-slider'),
				servicesPrev: $('.js-slick-prev'),
				servicesNext: $('.js-slick-next'),
				featSlider0: $('.js-feat-slider'),
				featSlider1: $('.js-feat-slider-1'),
				featSliderPrev: $('.js-feat-sld-prev'),
				featSliderNext: $('.js-feat-sld-next'),
				teamSilder: $('.js-team-slider'),
				teamSilderPrev: $('.js-team-sld-prev'),
				teamSilderNext: $('.js-team-sld-next'),
				simpleSlider: $('.js-simple-slider'),
				simpleSliderPrev: $('.js-slider-sld-prev'),
				simpleSliderNext: $('.js-slider-sld-next')
			},

			/*-------------------------------------------------------------------------------
   	# Initialize
   -------------------------------------------------------------------------------*/
			init: function init() {
				// get dom and strings
				var $dom = this.$dom;

				$dom.servicesSlider.slick({
					autoplay: true,
					infinite: true,
					slidesToShow: 3,
					slidesToScroll: 1,
					prevArrow: $dom.servicesPrev,
					nextArrow: $dom.servicesNext,
					responsive: [{
						breakpoint: 993,
						settings: {
							slidesToShow: 2
						}
					}, {
						breakpoint: 577,
						settings: {
							draggable: false,
							slidesToShow: 1
						}
					}]
				});

				var dualSliderOpt = {
					autoplay: true,
					infinite: true,
					slidesToShow: 4,
					slidesToScroll: 1,
					speed: 500,
					responsive: [{
						breakpoint: 1201,
						settings: {
							slidesToShow: 3
						}
					}, {
						breakpoint: 993,
						settings: {
							slidesToShow: 2
						}
					}, {
						breakpoint: 577,
						settings: {
							slidesToShow: 3
						}
					}]
				},
				    dualSliderOpt0 = jQuery.extend(true, {
					prevArrow: $dom.featSliderPrev,
					nextArrow: $dom.featSliderNext
				}, dualSliderOpt),
				    dualSliderOpt1 = jQuery.extend(true, {
					arrows: false
				}, dualSliderOpt);

				$dom.featSlider0.slick(dualSliderOpt0);
				$dom.featSlider1.slick(dualSliderOpt1);

				$dom.featSliderPrev.on('click', function () {
					setTimeout(function () {
						$dom.featSlider1.slick('slickPrev');
					}, 150);
				});

				$dom.featSliderNext.on('click', function () {
					setTimeout(function () {
						$dom.featSlider1.slick('slickNext');
					}, 150);
				});

				$dom.teamSilder.slick({
					autoplay: true,
					infinite: true,
					slidesToShow: 3,
					slidesToScroll: 1,
					prevArrow: $dom.teamSilderPrev,
					nextArrow: $dom.teamSilderNext,
					responsive: [{
						breakpoint: 1201,
						settings: {
							slidesToShow: 2
						}
					}, {
						breakpoint: 993,
						settings: {
							slidesToShow: 1
						}
					}]
				});

				$dom.simpleSlider.slick({
					infinite: true,
					slidesToShow: 3,
					slidesToScroll: 1,
					prevArrow: $dom.simpleSliderPrev,
					nextArrow: $dom.simpleSliderNext,
					responsive: [{
						breakpoint: 993,
						settings: {
							slidesToShow: 2
						}
					}, {
						breakpoint: 577,
						settings: {
							slidesToShow: 1
						}
					}]
				});
			}
		};
	}, { "./global": 5 }], 12: [function (require, module, exports) {
		jQuery(function ($) {
			// Site Menu
			var menu = require('./_site/menu');
			menu.init();

			// Slick Slider
			var sliders = require('./_site/sliders');
			sliders.init();

			// Admin Menu
			var adminHeader = require('./_site/adminHeader');
			adminHeader.init();

			// Animate Labels
			var searchF = require('./_site/searchF');
			searchF.init();

			// request form
			var requestForm = require('./_site/request-form');
			requestForm.init();

			// Accordian
			var accordian = require('./_site/accordian');
			accordian.init();

			// Circular
			var circular = require('./_site/circular');
			circular.init();

			// Miscscripts
			var miscScripts = require('./_site/misc-scripts');
			miscScripts.init();

			// industryLines
			var industryLines = require('./_site/industryLines');
			industryLines.init();

			// animatelabels
			var animatelabels = require('./_site/animatelabels');
			animatelabels.init();
		});
	}, { "./_site/accordian": 1, "./_site/adminHeader": 2, "./_site/animatelabels": 3, "./_site/circular": 4, "./_site/industryLines": 6, "./_site/menu": 7, "./_site/misc-scripts": 8, "./_site/request-form": 9, "./_site/searchF": 10, "./_site/sliders": 11 }] }, {}, [12]);